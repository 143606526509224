const StarBg = () => {
return (
<div>
    <div className="bg"></div>
    <div className="star-field">
        <div className="layer"></div>
        <div className="layer"></div>
        <div className="layer"></div>
    </div>
</div>
)};

export default StarBg;